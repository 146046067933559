.TableListTopContainer {
	background-color: white;
}

.TableListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr auto;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.TableListToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	//min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.TableListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.TableListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.TableListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.TableListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.TableListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.TableListOneTable {
	cursor: pointer;
	user-select: none;
}

.TableViewOneTable {
	width: 90vw;
	height: 90vw;
}

.TableViewOneImageContainer {
	position: relative;
	display: inline-block; /* <= shrinks container to image size */
	transition: transform 150ms ease-in-out;
}

.TableViewOneImageContainer svg {
  position: absolute;
  top: 0;
  left: 0;
}

.TableViewTableNumber {
	font-size: 20px;
	font-weight: bold;
}
