.SelectPropertyTopContainer {
}

.SelectPropertyQuestionText {
	font-size: 16px;
	padding: 5px;
	cursor: pointer;
	user-select: none;
}

