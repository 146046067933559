.DashboardMainTopContainer {
	background-color: white;
}

.DashboardMainDateContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-self: end;
	align-items: center;
	justify-content: center;
}

.DashboardYearMonthPopupMenuContainer1 {
	width: 200px;
	position: absolute;
	background: #fff;
	border: 1px solid #d0d0d0;
	padding: 20px;
	left: 0px;
	top: 5px;
	z-index: 1;
	position: relative;
}

.DashboardMainDatePrev {
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: right;
	user-select: none;
}

.DashboardMainDateString {
	color: black;
	font-size: 16px;
	padding: 5px;
	cursor: pointer;
	text-align: center;
	width: 80px;
	user-select: none;
}

.DashboardMainDateNext {
	display: table-cell;
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.DashboardMainContainer {
	padding-left: 20px;
	padding-right: 20px;
}

.DashboardMainFullName {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
}

.DashboardMainTaskLink {
	cursor: pointer;
	user-select: none;
}

.DashboardMainTaskNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.DashboardMainTaskNameItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.DashboardMainStatusHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: center;
}

.DashboardMainStatusItem {
	padding: 5px;
	font-size: 16px;
	width: 10vw;
	text-align: center;
}

.DashboardMainPartHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
}

.DashboardMainPartItem {
	padding: 5px;
	font-size: 16px;
	width: 30vw;
	text-align: left;
}

.DashboardMainDurationHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 20vw;
	text-align: left;
}

.DashboardMainDurationItem {
	padding: 5px;
	font-size: 16px;
	width: 20vw;
	text-align: left;
}

.DashboardMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr auto;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.DashboardMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	//min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.DashboardMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.DashboardMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.DashboardMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.DashboardMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DashboardMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DashboardMainTopHeaderContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 20px;
	width: 50%;
	height: 30px;
	justify-content: space-between;
}

.DashboardMainTopHeaderItem1 {
	font-size: 20px;
	min-width: 200px;
	cursor: pointer;
	position: relative;
	width: fit-content;
	justify-self: start;
	text-align: left;
	user-select: none;
}

.DashboardYearMonthPopupMenuContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	margin: 5px;
	width: 100%;
}

.DashboardYearMonthPopupMenuYears {
	font-size: 20px;
	align-self: start;
	text-align: left;
	cursor: pointer;
	user-select: none;
}

.DashboardYearMonthPopupMenuMonths {
	font-size: 20px;
	align-self: center;
	cursor: pointer;
	user-select: none;
}

.DashboardYearMonthPopupMenuOneYear {
	color: grey;
	font-size: 35px;
	text-align: left;
}

.DashboardYearMonthPopupMenuOneMonth {
	color: grey;
	font-size: 20px;
	text-align: left;
	padding-left: 20px;
}

.DashboardMainRevenueTopContainer {
	background-color: #f7f7f7;
}

.DashboardMainRevenueBoxContainer {
	margin: 20px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, 300px);
}

.DashboardMainRevenueOneBox {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: #aaaaaa;
	border-radius: 6px;
	font-size: 20px;
	padding: 5px;
	text-align: left;
	border: 1px solid;
	width: 300px;
	height: 150px;
	position: relative;
}

.DashboardMainRevenueBoxTitle {
	border-bottom: 1px solid;
	border-color: #aaaaaa;
	font-size: 16px;
}

.DashboardMainRevenueBoxAverage {
	font-size: 40px;
	text-align: right;
}

.DashboardMainRevenueBoxPercentageRed {
	color: red;
	font-size: 16px;
	text-align: right;
}

.DashboardMainRevenueBoxPercentageGreen {
	color: green;
	font-size: 16px;
	text-align: right;
}

.DashboardMainRevenueBoxPercentageBlack {
	color: black;
	font-size: 16px;
	text-align: right;
}

.DashboardMainRevenueBoxCompare {
	font-size: 16px;
	text-align: right;
}

.DashboardMainRevenueBoxTotal {
	font-size: 16px;
	position: absolute;
    bottom: 0;
	margin-bottom: 10px;
}

.DashboardMainGraphCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
	user-select: none;
}

.DashboardMainExpensesTopContainer {
	background-color: #f7f7f7;
}

.DashboardMainExpensesBoxContainer1 {
	margin: 20px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, 300px);
}

.DashboardMainExpensesBoxContainer2 {
	margin: 20px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, 300px);
}

.DashboardMainExpensesOneBoxNumber {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: #aaaaaa;
	border-radius: 6px;
	font-size: 20px;
	padding: 5px;
	text-align: left;
	border: 1px solid;
	width: 300px;
	height: 100px;
	position: relative;
}

.DashboardMainExpensesOneBoxText {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: #aaaaaa;
	border-radius: 6px;
	font-size: 14px;
	padding: 5px;
	text-align: left;
	border: 1px solid;
	width: 80vw;
	position: relative;
}

.DashboardMainExpensesBoxTitle {
	border-bottom: 1px solid;
	border-color: #aaaaaa;
	font-size: 16px;
}

.DashboardMainExpensesBoxAverage {
	font-size: 40px;
	text-align: right;
}

.DashboardMainExpensesBoxText {
	font-size: 16px;
	text-align: left;
}

.DashboardMainExpensesBoxPercentageRed {
	color: red;
	font-size: 16px;
	text-align: right;
}

.DashboardMainExpensesBoxPercentageGreen {
	color: green;
	font-size: 16px;
	text-align: right;
}

.DashboardMainExpensesBoxPercentageBlack {
	color: black;
	font-size: 16px;
	text-align: right;
}

.DashboardMainExpensesBoxCompare {
	font-size: 16px;
	text-align: right;
}

