.CameraListTopContainer {
	background-color: white;
}

.CameraListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr auto;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.CameraListToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	//min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.CameraListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.CameraListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.CameraListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.CameraListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.CameraListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.CameraListOneCamera {
	cursor: pointer;
	user-select: none;
}

.CameraViewOneCameraContainer {
}

.CameraViewOneCameraImage {
	max-width: 1000px;
}

